export enum UserType {
    SUPER_ADMIN = 0,
    ADMIN = 1,
    USER = 2
}

export const ROLES_LIST = [
    {
        id: UserType.ADMIN,
        name: 'Admin'
    },
    {
        id: UserType.USER,
        name: 'USER'
    },

];




export enum WeekDay {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6
}

export const WeekDaysArray = [{
    value: WeekDay.SUNDAY,
    name: 'Sun'
}, {
    value: WeekDay.MONDAY,
    name: 'Mon'
}, {
    value: WeekDay.TUESDAY,
    name: 'Tue'
}, {
    value: WeekDay.WEDNESDAY,
    name: 'Wed'
}, {
    value: WeekDay.THURSDAY,
    name: 'Thur'
}, {
    value: WeekDay.FRIDAY,
    name: 'Fri'
}, {
    value: WeekDay.SATURDAY,
    name: 'Sat'
}];


export enum DeviceType {
    ANDROID = 1,
    IOS = 2
}

export const DeviceTypeArray = [{
    value: DeviceType.ANDROID,
    name: 'Android'
}, {
    value: DeviceType.IOS,
    name: 'IOS'
}]


export enum QuestionType {
    Text = 'Text',
    FillInTheBlank = 'FillInTheBlank',
    TrueFalse = 'TrueFalse',
    MultiChoice = 'MultiChoice'
}
export const QUESTION_TYPES = [
    {
        id: QuestionType.Text,
        value: 'Plain Text',
        hasHint: false,
        hasOptions: false,
    },
    {
        id: QuestionType.FillInTheBlank,
        value: 'Fill in the Blanks',
        hasHint: true,
        hasOptions: false,
    },
    {
        id: QuestionType.TrueFalse,
        value: 'True or False',
        hasHint: true,
        hasOptions: true,
    },
    {
        id: QuestionType.MultiChoice,
        value: 'Multi Choice',
        hasHint: true,
        hasOptions: true,
    }
];

export enum ApprovalStatus {
    Pending = 1,
    Rejected = 2,
    WorkInProgress = 3,
    Approved = 4,
    PartialApproved = 5,
}

export const ApprovalStatusTxt = {
    "1": "Pending",
    "2": "Rework",
    "3": "Work In Progress",
    "4": "Approved",
    "5": "Partial Approved"
}

export enum ModuleRole {
    Manager = 1,
    InternalSME = 2,
    ExternalSME = 3,
    AUDIO_EDITOR = 4,
    CONTENT_DEVELOPER = 5,
    TECHNICAL_WRITTER = 6,
    VIEWER = 7
}


export const MODULE_ROLE_LIST = [
    {
        id: ModuleRole.Manager,
        name: 'Manager'
    },
    {
        id: ModuleRole.InternalSME,
        name: 'Internal SME'
    },
    {
        id: ModuleRole.ExternalSME,
        name: 'External SME'
    },
    {
        id: ModuleRole.CONTENT_DEVELOPER,
        name: 'Content Developer'
    },
    {
        id: ModuleRole.TECHNICAL_WRITTER,
        name: 'Technical Writer'
    },
    {
        id: ModuleRole.AUDIO_EDITOR,
        name: 'Audio Editor'
    },

];
